import '@fortawesome/fontawesome-free/css/all.min.css'
import '@factry/bulma/scss/bulma.scss'
import * as Sentry from '@factry/sentry'
import { settings as baseSettings, initialize } from '@factry/base'
import App from './App.svelte'

import background from './assets/background.jpg'
import logo from './assets/jacques-ijs-logo.png'

initialize({
  availableLocales: ['nl', 'en'],
  background,
  basePath: import.meta.env.VITE_BASE_PATH,
  defaultLocale: 'nl',
  logo,
  logoAlt: 'Jacques IJs',
  test: import.meta.env.VITE_TEST,
  versions: import.meta.env.VITE_VERSIONS,
  organization: 'jacquesijs',
})

Sentry.init({
  version: import.meta.env.VITE_VERSION,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  proxy: import.meta.env.VITE_BASE_PATH,
})

baseSettings.taskUrl = '/operations/detail?activeTab=weighing.operations.checklists'

const app = new App({
  target: document.body,
})

export default app
