<script>
  import Base from '@factry/base'
  import DownTime from '@factry/downtime-manager'
  import EquipmentManager from '@factry/equipment-manager'
  import InstructionManager from '@factry/instruction-manager'
  import MaterialManager from '@factry/material-manager'
  import MessageQueueManager from '@factry/message-queue-manager'
  import OrderManager from '@factry/order-manager'
  import ShiftManager from '@factry/shift-manager'
  import WasteManager from '@factry/waste-manager'
  import WeighingManager from '@jacquesijs/weighing-manager'
</script>

<Base
  options={[
    WeighingManager, EquipmentManager, DownTime, ShiftManager,
    MaterialManager, MessageQueueManager, InstructionManager,
    OrderManager, WasteManager,
  ]}
/>
